import { gql, useQuery } from "@apollo/client";
import { Header, Shimmers, TableComponent } from "components";
import ActionButton, { Action } from "components/buttons/action-button";
import config from "config";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTableData, useUrlState, wrapClick } from "utils";
import ViewMeterModelContainer from "./view";

const GET_METER_MODELS = gql`
  query GetMeterModels(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $brand: ID
  ) {
    rows: getMeterModels(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      brand: $brand
    ) {
      _id
      code
      digits
      phase
      type
      createdAt
      updatedAt
      communicationType
      currentRating
      mechanism
      name
      voltage
      brand {
        _id
        code
        name
      }
    }
    count: getMeterModelsCount(
      search: $search
      searchFields: $searchFields
      brand: $brand
    )
  }
`;

const MeterModelsPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code", "name"],
      sort: searchParams.sort || "",
      brand: searchParams.brand || undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_METER_MODELS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-full bg-gray-50'>
      <Header />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"meter models"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            // defaultView={"grid"}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="MeterModel"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Brand
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Model
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Digits
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Phase
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Type
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.code}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-500 dark:text-gray-100'>
                    {item?.brand?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-400'>
                    {item?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.digits || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.phase || "N/A"} phase
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.type || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      {!!searchParams.id?.length && (
        <>
          <ViewMeterModelContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default MeterModelsPage;

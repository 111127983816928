import { classNames, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { MeterRetirementItemsView, MeterRetirementSetView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useState } from "react";
import lodash from "lodash";

const GET_METER_RETIREMENT_SET = gql`
  query GetMeterRetirementSet($id: ID!) {
    meterRetirementSet: getMeterRetirementSet(id: $id) {
      _id
      code
      district {
        _id
        code
        name
      }
      description
      status
      retirements {
        _id
        requestCode
        meterCode
        servicePointCode
        status
        statusReason
      }
      createdAt
      updatedAt
      createdBy {
        _id
        lastName
        firstName
        phoneNumber
        profileImageUrl
      }
      meta {
        totalRetirements
        totalSuccessRetirements
        totalFailedRetirements
      }
    }
  }
`;

const orderTabs = [
  {
    name: "Retirement Details",
    href: "RetirementDetails",
  },
  {
    name: "Successful Retirements",
    href: "SuccessfulRetirements",
  },
  {
    name: "Failed Retirements",
    href: "FailedRetirements",
  },
];

export default function ViewMeterRetirementSetContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [orderTab, setOrderTab] = useState("RetirementDetails");
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_METER_RETIREMENT_SET, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const navigateItem = () => {
    navigate({
      to: `./${searchParams.id}/${searchParams?.code}`,
    });
  };

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding
      size="5xl"
      title="Meter Retirement Set Details"
      description="View the details of the meter retirement set"
      renderActions={() => (
        <>
          {orderTab === "SuccessfulRetirements" && (
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(navigateItem)}
          >
            View Meters
          </button>
          )}
        </>
      )}
    >
      <div className="block">
        <div className="border-b border-gray-200 bg-white px-6">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {orderTabs.map((_orderTab) => (
              <button
                key={_orderTab.name}
                onClick={wrapClick(__setOrderTab(_orderTab.href))}
                className={classNames(
                  orderTab === _orderTab.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={orderTab === _orderTab.href ? "page" : undefined}
              >
                {_orderTab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex flex-col w-full h-[55vh] overflow-y-auto">
        {orderTab === "RetirementDetails" && (
          <MeterRetirementSetView
            meterRetirementSet={data?.meterRetirementSet}
          />
        )}
        {orderTab === "SuccessfulRetirements" && (
          <MeterRetirementItemsView
            retirements={lodash.filter(data?.meterRetirementSet?.retirements, [
              "status",
              "Success",
            ])}
            status="Success"
          />
        )}
        {orderTab === "FailedRetirements" && (
          <MeterRetirementItemsView
            retirements={lodash.filter(data?.meterRetirementSet?.retirements, [
              "status",
              "Failed",
            ])}
            status="Failed"
          />
        )}
      </div>
    </Modal>
  );
}

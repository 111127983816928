import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AssignServiceOrderForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_SERVICE_ORDER = gql`
  query GetReplacementServiceOrder($id: ID!) {
    replacementServiceOrder: getReplacementServiceOrder(
      id: $id
    ) {
      _id
      code
      farmingOutContractor {
        _id
        code
        name
        contactPerson {
          fullName
          phoneNumber
          emailAddress
        }
      }
      farmingOutContractorUser {
        _id
        code
        username
        firstName
        lastName
        phoneNumber
        emailAddress
      }
      installationMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
        brand {
          _id
          code
          name
        }
        model {
          _id
          code
          name
          phase
          type
          digits
          voltage
          currentRating
          mechanism
          communicationType
        }
      }
    }
  }
`;

const ASSIGN_SERVICE_ORDER = gql`
  mutation AssignReplacementServiceOrder(
    $id: ID!
    $assignee: ID!
    $installationMeter: ID!
  ) {
    assignReplacementServiceOrder: assignReplacementServiceOrderMeterContractor(
      id: $id
      assignee: $assignee
      installationMeter: $installationMeter
    ) {
      _id
    }
  }
`;

export default function AssignReplacementServiceOrderContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [assignReplacementServiceOrder] = useMutation(ASSIGN_SERVICE_ORDER);

  const form = useFormik({
    initialValues: {
      contractorInfo: {
        contractor: null as any,
      },
      contractorUserInfo: {
        contractorUser: null as any,
      },
      meterInfo: {
        meter: null as any,
      },
    },
    onSubmit: async (values) => {
      await assignReplacementServiceOrder({
        variables: {
          id: searchParams.id,
          assignee: values.contractorUserInfo?.contractorUser?._id,
          installationMeter: values.meterInfo?.meter?._id,
        },
      }).then(({ data }) => {
        if (data.assignReplacementServiceOrder._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Assigned Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not assign Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      contractorInfo: {
        contractor:
          data?.replacementServiceOrder?.farmingOutContractor || (null as any),
      },
      contractorUserInfo: {
        contractorUser:
          data?.replacementServiceOrder?.farmingOutContractorUser ||
          (null as any),
      },
      meterInfo: {
        meter:
          data?.replacementServiceOrder?.installationMeter || (null as any),
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.replacementServiceOrder, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      loading={loading}
      title="Assign Replacement Service Order"
      size="5xl"
      description="Provide the details to assign service order"
    >
      {data?.replacementServiceOrder?._id && (
        <AssignServiceOrderForm form={form} />
      )}
    </Modal>
  );
}

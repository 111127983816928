import {
  ExclamationTriangleIcon
} from "@heroicons/react/20/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import lodash from "lodash";
import moment from "moment";
import { FC, useMemo } from "react";
import { classNames } from "utils";

const PrepaidSyncStatuses = ["Pending", "Completed"] as const;
type PrepaidSyncStatus = (typeof PrepaidSyncStatuses)[number];
const PrepaidSyncActions = [
  "SetupContract",
  "SetupInstall",
  "SetupUninstall",
  "ReplaceMeter",
  "CreateDebt",
  "UpdateDebt",
  "CancelDebt",
  "UpdateCustomer",
  "UpdateServicePoint",
  "UpdateTariffClass",
  "Block",
  "Unblock",
] as const;
type PrepaidSyncAction = (typeof PrepaidSyncActions)[number];

interface IPrepaidSyncHistory {
  action: string;
  status: "Success" | "Failed";
  message: string;
  data: any;
  timestamp: Date;
}

export interface IPrepaidSync {
  _id: string;
  code: string;
  action: PrepaidSyncAction;
  system: {
    _id: string;
    code: string;
    name: string;
  };
  meter: any;
  data: JSON;
  history: IPrepaidSyncHistory[];
  status: PrepaidSyncStatus;
  stage: string;
  completedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

interface ReplacementServiceOrderPrepaymentViewProps {
  serviceOrder: {
    resolution: any;
    installationSync: IPrepaidSync;
    uninstallationSync: IPrepaidSync;
    presetAmountSync: IPrepaidSync;
    installationMeterSystemSyncMethod: "ReverseSync" | "ForwardSync";
    reverseSyncedAt: Date;
    installationMeter: any;
    installedMeter: any;
  };
}

const PrepaymentSyncHistoryView: FC<{ history: IPrepaidSyncHistory[] }> = ({
  history,
}) => {
  return (
    <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200 h-[40vh] overflow-y-auto">
      <ul className="space-y-6">
        {history?.map((activityItem, activityItemIdx) => (
          <li key={activityItemIdx} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === history.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            <>
              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                {activityItem.status === "Success" ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                <div className="flex justify-between gap-x-4">
                  <div className="py-0.5 text-sm text-gray-500 items-center flex">
                    <span className="font-medium text-gray-900 mr-3">
                      {lodash.startCase(activityItem?.action)}
                    </span>
                    <span
                      className={classNames(
                        activityItem.status === "Success"
                          ? "bg-green-100 text-green-700"
                          : "bg-red-100 text-red-700",
                        "inline-flex items-center gap-x-1.5 rounded-full px-1.5 py-0.5 text-xs font-medium"
                      )}
                    >
                      <svg
                        className={classNames(
                          activityItem.status === "Success"
                            ? "fill-green-500"
                            : "fill-red-500",
                          "h-1.5 w-1.5"
                        )}
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {lodash.startCase(activityItem?.status)}
                    </span>
                  </div>
                  <time className="flex-none py-0.5 text-sm leading-5 text-gray-500">
                    {moment(activityItem?.timestamp).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                  </time>
                </div>
                <p className="text-sm leading-6 text-gray-500">
                  {activityItem?.message}
                </p>
              </div>
            </>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ReplacementServiceOrderPrepaymentView: FC<
  ReplacementServiceOrderPrepaymentViewProps
> = ({
  serviceOrder: {
    resolution,
    uninstallationSync,
    installationSync,
    installationMeterSystemSyncMethod,
    reverseSyncedAt,
    installationMeter,
    installedMeter,
  },
}) => {
  const installationSyncMeter = useMemo(
    () =>
      installationMeterSystemSyncMethod === "ReverseSync"
        ? installationMeter
        : installedMeter,
    [installationMeter, installationMeterSystemSyncMethod, installedMeter]
  );

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Forward Sync Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about sending customer information to prepayment system
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4 space-y-3">
          <div
            className={classNames(
              "border-gray-300 dark:border-gray-600",
              "border-2 border-dashed  rounded-md p-3 space-y-6 divide-y divide-gray-200"
            )}
          >
            <div className="grid grid-cols-3 gap-4">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Meter Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {installationSyncMeter?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Meter Make
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {installationSyncMeter?.modelMeta?.brandCode || "N/A"} -{" "}
                  {installationSyncMeter?.modelMeta?.brandName || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Meter Model
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {installationSyncMeter?.modelMeta?.modelCode || "N/A"} -{" "}
                  {installationSyncMeter?.modelMeta?.modelName || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Meter System
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {installationSyncMeter?.modelMeta?.systemCode || "N/A"} -{" "}
                  {installationSyncMeter?.modelMeta?.systemName || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Status
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {installationSync?.status}
                </div>
              </div>
              {installationSync?.completedAt && (
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Completed At
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {installationSync?.completedAt
                      ? moment(installationSync?.completedAt).format(
                          "DD-MM-YYYY hh:mm A"
                        )
                      : "N/A"}
                  </div>
                </div>
              )}
              {/* <div className="col-span-3">
                <Disclosure>
                  {({ open }) => (
                    <div
                      className={classNames(
                        open ? "rounded-md" : "rounded-md",
                        " bg-gray-100"
                      )}
                    >
                      <Disclosure.Button
                        className={classNames(
                          open ? "rounded-md" : "rounded-md",
                          "flex w-full justify-between bg-gray-200 hover:bg-gray-300 px-3 py-2 text-left text-sm font-normal text-gray-700 focus:outline-none "
                        )}
                      >
                        <span>Details Sent To Prepayment System</span>
                        <ChevronUpIcon
                          className={`${
                            !open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-gray-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="text-gray-600 text-xs font-mono font-light p-4">
                        <pre className="">
                          {JSON.stringify(
                            stringifyObject(installationSync?.data),
                            null,
                            4
                          )}
                        </pre>
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div> */}
            </div>
          </div>
          {installationSync?.history?.length ? (
            <PrepaymentSyncHistoryView
              history={installationSync?.history || []}
            />
          ) : (
            <div
              className={classNames(
                false
                  ? "border-amber-400 bg-amber-50 dark:border-amber-600"
                  : "border-gray-300 dark:border-gray-600",
                "border-2 border-dashed  rounded-md p-16 space-y-6 divide-y divide-gray-200 text-center text-sm"
              )}
            >
              No History From Prepayment System Yet
            </div>
          )}
        </div>
      </div>
      {installationMeterSystemSyncMethod === "ReverseSync" && (
        <div className="pt-6">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
                Reverse Sync Information
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about initial reading and meter details from prepayment
                system
              </p>
            </div>
            <div />
          </div>
          <div className="mt-4 space-y-3">
            {reverseSyncedAt ? (
              <>
                <div
                  className={classNames(
                    resolution?.reverseSyncMeter?._id !== installationMeter?._id
                      ? "border-amber-400 bg-amber-50 dark:border-amber-600"
                      : "border-gray-300 dark:border-gray-600",
                    "border-2 border-dashed  rounded-md p-3 space-y-6 divide-y divide-gray-200"
                  )}
                >
                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Meter Number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {resolution?.reverseSyncMeter?.code || "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Meter Make
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {resolution?.reverseSyncMeter?.modelMeta?.brandCode ||
                          "N/A"}{" "}
                        -{" "}
                        {resolution?.reverseSyncMeter?.modelMeta?.brandName ||
                          "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Meter Model
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {resolution?.reverseSyncMeter?.modelMeta?.modelCode ||
                          "N/A"}{" "}
                        -
                        {resolution?.reverseSyncMeter?.modelMeta?.modelName ||
                          "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Reverse Sync Date
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {reverseSyncedAt
                          ? moment(reverseSyncedAt).format("DD-MM-YYYY hh:mm A")
                          : "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Installation Date
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {resolution?.reverseSyncReading?.readingDate
                          ? moment(
                              resolution?.reverseSyncReading?.readingDate
                            ).format("DD-MM-YYYY hh:mm A")
                          : "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Initial Reading
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {resolution?.reverseSyncReading?.readingValue ?? "N/A"}{" "}
                        KWh
                      </div>
                    </div>
                  </div>
                </div>
                {resolution?.reverseSyncMeter?._id !==
                  installationMeter?._id && (
                  <div className="rounded-md bg-amber-50 border border-amber-100 p-3 mt-3">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                          className="h-5 w-5 text-amber-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-2">
                        <h3 className="text-sm font-medium text-amber-800">
                          Attention needed
                        </h3>
                        <div className="mt-0 text-sm text-amber-700">
                          <p>
                            {" "}
                            Meter assigned is not the same as meter installed on
                            prepayment system. Expected meter{" "}
                            <span className="text-sm font-medium text-amber-800">
                              {installationMeter?.code}
                            </span>{" "}
                            (model: {installationMeter?.modelMeta?.brandName} -{" "}
                            {installationMeter?.modelMeta?.modelName}) but got{" "}
                            <span className="text-sm font-medium text-amber-800">
                              {resolution?.reverseSyncMeter?.code}
                            </span>{" "}
                            (model:{" "}
                            {resolution?.reverseSyncMeter?.modelMeta?.brandName}{" "}
                            -{" "}
                            {resolution?.reverseSyncMeter?.modelMeta?.modelName}
                            )
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div
                className={classNames(
                  false
                    ? "border-amber-400 bg-amber-50 dark:border-amber-600"
                    : "border-gray-300 dark:border-gray-600",
                  "border-2 border-dashed  rounded-md p-16 space-y-6 divide-y divide-gray-200 text-center text-sm"
                )}
              >
                Reverse Sync Data Not In Yet, Kindly Resolve Worktask In AMC
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReplacementServiceOrderPrepaymentView;

import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Shimmers, TableComponent } from "components";
import ActionButton, { Action } from "components/buttons/action-button";
import OfficeHeader from "components/layouts/office-header";
import config from "config";
import {
  MeterBrandPicker,
  MeterModelPicker
} from "containers";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, useTableData, useUrlState, wrapClick } from "utils";
import ViewMeterContainer from "./view";

const GET_METERS = gql`
  query GetMeters(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $brand: ID
    $model: ID
    $modelType: MeterModelType
    $meterModelPhase: String
    $location: MeterLocation
    $status: MeterStatus
  ) {
    rows: getMeters(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      brand: $brand
      model: $model
      modelType: $modelType
      meterModelPhase: $meterModelPhase
      location: $location
      status: $status
    ) {
      _id
      code
      batchCode
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      location
      status
      createdAt
      updatedAt
      batchCode
      brand {
        _id
        code
        name
      }
      model {
        _id
        code
        name
        phase
        type
        digits
        voltage
        currentRating
        mechanism
        communicationType
        createdAt
        updatedAt
      }
    }
    count: getMetersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      brand: $brand
      model: $model
      modelType: $modelType
      meterModelPhase: $meterModelPhase
      location: $location
      status: $status
    )
  }
`;

const MetersPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [model, setModel] = useUrlState("model");
  const [brand, setBrand] = useUrlState("brand");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code"],
      sort: searchParams.sort || "",
      brand: searchParams.brand || undefined,
      model: searchParams.model || undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_METERS, {
    variables: filter,
    pollInterval,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "code" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <OfficeHeader />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"meters"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="Meter"
            //     filter={filter}
            //   />
            // )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'>
                <MeterBrandPicker
                  id='brand'
                  labelHidden={true}
                  label={""}
                  placeholder='Select Brand'
                  setFieldValue={(_: any, value: string) => {
                    setModel("");
                    setBrand(value);
                  }}
                  values={{ brand }}
                  rawId={true}
                />
                {searchParams.brand && (
                  <MeterModelPicker
                    id='model'
                    labelHidden={true}
                    label={""}
                    placeholder='Select Model'
                    setFieldValue={(_: any, value: string) => setModel(value)}
                    values={{ model }}
                    filter={{ brand }}
                    rawId={true}
                  />
                )}
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Number
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Batch Number
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Brand
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Model
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Type
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Phase
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Tension
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Amperage
                </th>
                {!searchParams.location && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Location
                  </th>
                )}
                {searchParams.location !== "CentralStore" && (
                  <>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Region
                    </th>
                    {searchParams.location !== "RegionStore" && (
                      <th
                        scope='col'
                        className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                      >
                        District
                      </th>
                    )}
                  </>
                )}
                {!searchParams.location ||
                  (searchParams.location === "Field" && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Status
                    </th>
                  ))}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                {!searchParams.location && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                {searchParams.location !== "CentralStore" && (
                  <>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                    {searchParams.location !== "RegionStore" && (
                      <td className='px-6 py-4 border-b border-gray-200'>
                        <Shimmers.SingleShimmer />
                      </td>
                    )}
                  </>
                )}
                {!searchParams.location ||
                  (searchParams.location === "Field" && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  ))}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.batchCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.brand?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.model?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      item?.model?.type === "Prepaid"
                        ? "bg-cyan-100 text-cyan-800 dark:bg-cyan-600 dark:text-cyan-100"
                        : "",
                      item?.model?.type === "Postpaid"
                        ? "bg-primary-100 text-primary-800 dark:bg-primary-600 dark:text-primary-50"
                        : "",
                      !item?.model?.type
                        ? "bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{item?.model?.type || "Unknown"}</span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.model?.phase || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.model?.voltage || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.model?.currentRating || "N/A"}
                  </div>
                </td>
                {!searchParams.location && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <span
                      className={classNames(
                        item?.location === "CentralStore"
                          ? "bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50"
                          : "",
                        item?.location === "RegionStore"
                          ? "bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50"
                          : "",
                        item?.location === "DistrictStore"
                          ? "bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50"
                          : "",
                        item?.location === "Field"
                          ? "bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50"
                          : "",
                        !item?.location ? "bg-gray-100 text-gray-800" : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>{item?.location || "Unknown"}</span>
                    </span>
                  </td>
                )}
                {searchParams.location !== "CentralStore" && (
                  <>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.region?.name || "N/A"}
                      </div>
                    </td>
                    {searchParams.location !== "RegionStore" && (
                      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                        <div className='text-gray-500 dark:text-gray-400'>
                          {item?.district?.name || "N/A"}
                        </div>
                      </td>
                    )}
                  </>
                )}
                {!searchParams.location ||
                  (searchParams.location === "Field" && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <span
                        className={classNames(
                          item?.status === "Store"
                            ? "bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50"
                            : "",
                          item?.status === "PendingAssignment"
                            ? "bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50"
                            : "",
                          item?.status === "PendingInstallation"
                            ? "bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50"
                            : "",
                          item?.status === "Active"
                            ? "bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50"
                            : "",
                          item?.status === "Disconnected"
                            ? "bg-[#eec96b5a] text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50"
                            : "",
                          item?.status === "Damaged"
                            ? "bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50"
                            : "",
                          !item?.status ? "bg-gray-100 text-gray-800" : "",
                          "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                        )}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 10 10'
                          fill='currentColor'
                          className='w-1.5 h-1.5'
                        >
                          <circle
                            fillRule='evenodd'
                            cx='5'
                            cy='5'
                            r='5'
                            clipRule='evenodd'
                          />
                        </svg>
                        <span>{item?.status || "Unknown"}</span>
                      </span>
                    </td>
                  ))}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      {!!searchParams.id?.length && (
        <>
          <ViewMeterContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default MetersPage;

import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { OfficeHeader, Shimmers, TableComponent } from "components";
import ActionButton, { Action } from "components/buttons/action-button";
import { Avatar } from "components/core";
import config from "config";
import lodash from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  classNames,
  useDownloadFile,
  useTableData,
  useUrlState,
  wrapClick,
} from "utils";
import ViewContractorExportContainer from "./view";
import { currentDistrictVar } from "apollo/cache/auth";

const GET_EXPORTS = gql`
  query GetContractorExports(
    $page: Int
    $pageSize: Int
    $sort: String 
    $search: String
    $searchFields: [String!]
    $district: ID
    $contractor: ID
  ) {
    rows: getContractorExports(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      district: $district
      contractor: $contractor
    ) {
      _id
      code
      description
      createdBy {
        _id
        code
        emailAddress
        firstName
        gender
        ghanaCardNumber
        lastName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
      status
      type
      fileUrl
      startedAt
      completedAt
      district {
        _id
        code
        name
      }
      meta {
        exportedCount
        totalDataCount
      }
    }
    count: getContractorExportsCount(
      search: $search
      searchFields: $searchFields
      district: $district
      contractor: $contractor
    )
  }
`;

const ExportsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "Failed Shipment.xlsx",
  });

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const currentDistrict = useReactiveVar(currentDistrictVar);

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code", "description"],
      sort: searchParams.sort || "",
      ...(currentDistrict ? { district: currentDistrict } : {}),
      status: searchParams.orderStatus || undefined,
    }),
    [searchParams, currentDistrict]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_EXPORTS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <OfficeHeader />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"exports"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                {!searchParams?.district && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    District
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Type | Description
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Progress
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Created By
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Started At | Completed At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                {!searchParams?.district && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {moment(item?.createdAt).format(dateFormat + ", hh:mm A")}
                  </div>
                </td>
                {!searchParams?.district && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.district?.name || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.district?.code || "N/A"}
                    </div>
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {lodash.startCase(item?.type) || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.description || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="py-1" aria-hidden="true">
                    <div className="overflow-hidden rounded-full bg-gray-200 w-64">
                      <div
                        className="h-2 rounded-full bg-primary-600"
                        style={{
                          width: numeral(
                            item?.meta?.exportedCount /
                              item?.meta?.totalDataCount
                          ).format("0.00%"),
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="hidden grid-cols-2 text-sm text-gray-500 sm:grid">
                    <div className="">
                      {numeral(item?.meta?.exportedCount).format("0,0")}/
                      {numeral(item?.meta?.totalDataCount).format("0,0")}
                    </div>
                    <div className="text-right">
                      {numeral(
                        item?.meta?.exportedCount / item?.meta?.totalDataCount
                      ).format("0.00%")}
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.createdBy?.lastName || "")?.trim(),
                            (item?.createdBy?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.createdBy?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {[
                          (item?.createdBy?.lastName || "")?.trim(),
                          (item?.createdBy?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.createdBy?.code || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      item?.status === "Pending"
                        ? "bg-amber-100 text-amber-800"
                        : "",
                      item?.status === "InProgress"
                        ? "bg-yellow-100 text-yellow-800"
                        : "",
                      item?.status === "Completed"
                        ? "bg-green-100 text-green-800"
                        : "",
                      !item?.status ? "bg-gray-200 text-gray-800" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{lodash.startCase(item?.status) || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.startedAt
                      ? moment(item?.startedAt).format(dateFormat + ", hh:mm A")
                      : "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.completedAt
                      ? moment(item?.completedAt).format(
                          dateFormat + ", hh:mm A"
                        )
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action="download"
                      disabled={!item?.fileUrl || downloadLoading}
                      onClick={() => downloadAction(item?.fileUrl)}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      {!!searchParams.id?.length && (
        <>
          <ViewContractorExportContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ExportsPage;

import { gql, useMutation, useQuery } from "@apollo/client";
import { ContractorUserView, Modal } from "components";
import { FC } from "react";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const RESET_FINGER_PRINT = gql`
  mutation ResetFingerPrint($id: ID!) {
    resetContractorUserFingerprint(id: $id) {
      _id
    }
  }
`;

export const GET_CONTRACTOR_USER = gql`
  query GetContractorUser($id: ID!) {
    user: getContractorUser(id: $id) {
      _id
      code
      lastName
      firstName
      username
      ghanaCardNumber
      phoneNumber
      emailAddress
      gender
      profileImageUrl
      meta {
        isPasswordSet
        isBlocked
        isDeleted
        lastLoginAt
      }
      createdAt
      updatedAt
    }
  }
`;

interface ViewContractorUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const ResetContractorUserFingerprintContainer: FC<ViewContractorUserContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CONTRACTOR_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [resetFingerprint, { loading: resetting }] = useMutation(
    RESET_FINGER_PRINT,
    {
      onCompleted: () => {
        refetch?.();
        // setOpen(false);
        toast(
          JSON.stringify({
            type: "success",
            title: "Fingerprint reset successfully",
          })
        );
      },
      onError: (error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: "Could not reset fingerprint",
          })
        );
      },
    }
  );

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title='Reset Agent Fingerprint'
      description='Details of agent to reset fingerprint'
      renderActions={() => (
        <>
        
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={() =>
              resetFingerprint({ variables: { id: searchParams.id } })
            }
          >
            {resetting ? " Resetting ..." : " Reset Fingerprint"}
          </button>
        </>
      )}
    >
      {data?.user?._id && <ContractorUserView user={data?.user} />}
    </Modal>
  );
};

export default ResetContractorUserFingerprintContainer;

import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { FC, useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { classNames, wrapClick } from "utils";

interface TableBodyComponentProps<TData = any> {
  data: {
    rows: TData[];
    total?: number;
    page?: number;
    pageSize?: number;
    totalPages?: number;
  };
  renderColumns?: FC;
  renderItem?: FC<TData>;
  renderHeaderItems?: FC;
  renderLoader?: FC;
  loading?: boolean;
  noSpace?: boolean;
  tableRef?: any;
  title?: string;
  exportTypes?: ("EXCEL" | "PDF")[];
  exportFileName?: string;
}

const TableBodyComponent: FC<TableBodyComponentProps> = ({
  renderColumns,
  data,
  renderItem,
  loading,
  renderLoader,
  noSpace,
  exportFileName,
  title,
  exportTypes,
  renderHeaderItems
}) => {
  const ref = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: ref.current,
    filename: exportFileName || "Export",
    sheet: "Summary",
  });
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: exportFileName || "Export",
    bodyClass: "w-[1480px] justify-center m-0.5",
  });

  return (
    <>
      {title ? (
        <div className="flex justify-between items-center">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            {title}
          </h3>
          <div className="space-x-3 flex">
          {renderHeaderItems && renderHeaderItems({})}
            {exportTypes?.includes("EXCEL") && (
            <button
              type="button"
              onClick={wrapClick(onDownload)}
              className=" inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              <CloudArrowDownIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Export To Excel
            </button>
            )}
            {exportTypes?.includes("PDF") && (
            <button
              type="button"
              onClick={wrapClick(handlePrint)}
              className=" inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              <CloudArrowDownIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Export To PDF
            </button>
            )}
          </div>
        </div>
      ) : null}
      <div
        className={classNames(
          title ? "mt-3": "",
          noSpace ? "" : "sm:-mx-6 lg:-mx-0 -my-2",
          "overflow-x-auto no-scrollbar flex-1 flex overflow-y-hidden"
        )}
      >
        <div
          className={classNames(
            noSpace ? "" : "sm:px-6 lg:px-6",
            "align-middle min-w-full flex-1 flex flex-col overflow-y-auto no-scrollbar"
          )}
        >
          <table
            ref={ref}
            className="border-separate min-w-full border-x border-gray-200 print:border-gray-700 "
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50 dark:bg-gray-800">
              {renderColumns?.({}) ?? (
                <tr>
                  {Object.keys(data.rows[0])
                    .filter((field) => !_.isObject(data.rows[0][field]))
                    .map((field) => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider whitespace-nowrap"
                      >
                        {_.startCase(field)}
                      </th>
                    ))}
                </tr>
              )}
            </thead>
            <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700 overflow-y-auto">
              {loading
                ? renderLoader?.({}) ?? (
                    <tr>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200  flex-1">
                        <div className="bg-gray-500 dark:bg-gray-400 h-3 rounded-md w-full animate-pulse" />
                      </td>
                    </tr>
                  )
                : data.rows?.map(
                    (item) =>
                      renderItem?.(item) ?? (
                        <tr key={item._id}>
                          {Object.keys(data.rows[0])
                            .filter((field) => !_.isObject(data.rows[0][field]))
                            .map((field) => (
                              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 ">
                                {_.isBoolean(item[field])
                                  ? item[field]
                                    ? "Yes"
                                    : "No"
                                  : item[field]}
                              </td>
                            ))}
                        </tr>
                      )
                  )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableBodyComponent;

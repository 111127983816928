import { FC } from "react";
import ReplacementMaterialInfoSummary from "./materials-info-summary";
import ServiceInfoSummary from "./service-info-summary";

interface InstallationServiceOrderResolutionViewProps {
  meterRetirement: any;
}

const InstallationServiceOrderResolutionView: FC<
  InstallationServiceOrderResolutionViewProps
> = ({ meterRetirement }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Meter Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about meter removed from premises
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <ServiceInfoSummary
            data={{ ...meterRetirement }}
          />
        </div>
      </div>
      <div className="pt-6 ">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Materials Infomation
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about materials removed alongside meter
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <ReplacementMaterialInfoSummary
            data={{
              recoveredMaterials: meterRetirement?.resolution?.recoveredMaterials,
            }}
          />
        </div>
      </div>
      <div className="pt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Notes
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {meterRetirement?.resolution?.notes || "N/A"}
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default InstallationServiceOrderResolutionView;

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  Modal,
  WorkOrderAssignmentsView,
  WorkOrderView
} from "components";
import lodash from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, useDownloadFile, wrapClick } from "utils";

export const GET_SERVICE_ORDER = gql`
  query GetReplacementServiceOrderAssignment($id: ID!) {
    workOrder: getReplacementServiceOrderAssignment(id: $id) {
      _id
      code
      status
      assignments {
        _id
        sn
        cmsRegionCode
        districtCode
        serviceOrderCode
        serviceOrderType
        servicePointCode
        customerName
        customerPhone
        emailAddress
        geoCode
        tariffClassCode
        meterPhase
        ghanaPostAddress
        farmingOutContractor
        farmingOutAccount
        presetAmount
        batchId
        orgId
        cosemLogicalDeviceName
        mfgSerialNumber
        customerSerialNumber
        manufacturer
        modelType
        ipAddress
        gprsModuleSerialNumber
        firmwareType
        firmwareVersion
        llsSecret
        hlsSecret
        authentication
        encryptionKey
        macAddress
        badgeId
        status
        statusReason
      }
      meta {
        totalAssignments
        totalFailedAssignments
        totalSuccessAssignments
      }
      createdBy {
        _id
        code
        lastName
        firstName
        emailAddress
        phoneNumber
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query($id: ID!) {
    url: getReplacementServiceOrderAssignmentExportUrl(id: $id)
  }
`;

const orderTabs = [
  {
    name: "Work Order Details",
    href: "WorkOrderDetails",
  },
  {
    name: "Successful Assignments",
    href: "SuccessfulAssignments",
  },
  {
    name: "Failed Assignments",
    href: "FailedAssignments",
  },
];

export default function ViewReplacementServiceOrderAssignmentContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("WorkOrderDetails");
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "Failed Assignments.xlsx",
  });

  const handleDownloadItem = () => {
    createDataExport({
      variables: {
        id: searchParams.id,
      },
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.url) {
        downloadAction(`${data.url}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      hidePadding={true}
      title="Work Order Information"
      size="6xl"
      description="Details of work order are shown below"
      renderActions={() => (
        <>
          {orderTab === "FailedAssignments" && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(handleDownloadItem)}
              disabled={gettingUrl || downloadLoading}
            >
              {gettingUrl
                ? "Processing file for download"
                : downloadLoading
                  ? "Downloading"
                  : "Download Failed Assignments"}
            </button>
          )}
        </>
      )}
    >
      {data?.workOrder?._id && (
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="block">
            <div className="border-b border-gray-200 bg-white px-6">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {orderTabs.map((_orderTab) => (
                  <button
                    key={_orderTab.name}
                    onClick={wrapClick(__setOrderTab(_orderTab.href))}
                    className={classNames(
                      orderTab === _orderTab.href
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      orderTab === _orderTab.href ? "page" : undefined
                    }
                  >
                    {_orderTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className="flex flex-col w-full h-[75vh] overflow-y-auto">
            {orderTab === "WorkOrderDetails" && (
              <WorkOrderView workOrder={data?.workOrder} />
            )}
            {orderTab === "SuccessfulAssignments" && (
              <WorkOrderAssignmentsView
                assignments={lodash.filter(data?.workOrder?.assignments, [
                  "status",
                  "Success",
                ])}
                type="Successful"
              />
            )}
            {orderTab === "FailedAssignments" && (
              <WorkOrderAssignmentsView
                assignments={lodash.filter(data?.workOrder?.assignments, [
                  "status",
                  "Failed",
                ])}
                type="Failed"
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";
import lodash from "lodash";

interface ContractorExportViewProps {
  contractorExport: {
    status: string;
    type: string;
    meta: any;
    _id: string;
    code: string;
    description: string;
    district: {
      _id: string;
      code: string;
      name: string;
    };
    createdBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    params: {
      args: any;
    };
    startedAt: string;
    completedAt: string;
    createdAt: string;
    updatedAt: string;
  };
}

const ContractorExportView: FC<ContractorExportViewProps> = ({
  contractorExport,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Export Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.code || "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Description
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.description || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.status || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                District
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.district?.name || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Created By
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {[
                  (contractorExport?.createdBy?.lastName || "")?.trim(),
                  (contractorExport?.createdBy?.firstName || "")?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Created At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {moment(contractorExport?.createdAt).format(
                  dateFormat + ", hh:mm A"
                )}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Started At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.startedAt
                  ? moment(contractorExport?.startedAt).format(
                      dateFormat + ", hh:mm A"
                    )
                  : "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Completed At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.completedAt
                  ? moment(contractorExport?.completedAt).format(
                      dateFormat + ", hh:mm A"
                    )
                  : "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Progress
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                <div className="py-1" aria-hidden="true">
                  <div className="overflow-hidden rounded-full bg-gray-200 w-full">
                    <div
                      className="h-2 rounded-full bg-primary-600"
                      style={{
                        width: numeral(
                          contractorExport?.meta?.exportedCount /
                            contractorExport?.meta?.totalDataCount
                        ).format("0.00%"),
                      }}
                    ></div>
                  </div>
                </div>
                <div className="hidden grid-cols-2 text-sm text-gray-500 sm:grid">
                  <div className="">
                    {numeral(contractorExport?.meta?.exportedCount).format(
                      "0,0"
                    )}
                    /
                    {numeral(contractorExport?.meta?.totalDataCount).format(
                      "0,0"
                    )}
                  </div>
                  <div className="text-right">
                    {numeral(
                      contractorExport?.meta?.exportedCount /
                        contractorExport?.meta?.totalDataCount
                    ).format("0.00%")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Filter Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {lodash.startCase(contractorExport?.params?.args?.status) ||
                  "All"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Contractor
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.params?.args?.farmingOutContractor?.name ||
                  "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Contractor Agent
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.params?.args?.farmingOutContractorUser
                  ? [
                      contractorExport?.params?.args?.farmingOutContractorUser
                        ?.firstName,
                      contractorExport?.params?.args?.farmingOutContractorUser
                        ?.lastName,
                    ]
                      .join(" ")
                      .trim()
                  : "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Priority
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {lodash.startCase(contractorExport?.params?.args?.priority) ||
                  "All"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Category
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {lodash.startCase(contractorExport?.params?.args?.category) ||
                  "All"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Sync Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {lodash.startCase(
                  contractorExport?.params?.args
                    ?.installationMeterSystemSyncStatus
                ) || "All"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                From Date
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.params?.args?.fromDate
                  ? moment(contractorExport?.params?.args?.fromDate).format(
                      dateFormat
                    )
                  : "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                To Date
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {contractorExport?.params?.args?.toDate
                  ? moment(contractorExport?.params?.args?.toDate).format(
                      dateFormat
                    )
                  : "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorExportView;

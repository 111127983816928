import { gql, useQuery } from "@apollo/client";
import { ContractorExportView, Modal } from "components";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useDownloadFile, wrapClick } from "utils";

const GET_EXPORT = gql`
  query GetContractorExport($id: ID!) {
    contractorExport: getContractorExport(id: $id) {
      _id
      code
      description
      createdBy {
        _id
        code
        emailAddress
        firstName
        gender
        ghanaCardNumber
        lastName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
      status
      type
      fileUrl
      startedAt
      completedAt
      district {
        _id
        code
        name
      }
      meta {
        exportedCount
        totalDataCount
      }
      params {
        args {
          description
          status
          district
          installationMeterSystemSyncStatus
          priority
          category
          farmingOutContractor {
            _id
            code
            name
          }
          farmingOutContractorUser {
            _id
            code
            emailAddress
            firstName
            gender
            ghanaCardNumber
            lastName
            phoneNumber
            profileImageUrl
          }
          fromDate
          toDate
          dateField
        }
        ctx
      }
    }
  }
`;

export default function ViewContractorExportContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_EXPORT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "Failed Shipment.xlsx",
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Meter Shipment Information"
      hidePadding
      size="5xl"
      description="Details of meter shipment are shown below"
      renderActions={() => (
        <>
          {data?.contractorExport?.fileUrl && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(() => {
                downloadAction(data?.contractorExport?.fileUrl);
              })}
              disabled={downloadLoading}
            >
              {downloadLoading ? "Downloading" : "Download Export"}
            </button>
          )}
        </>
      )}
    >
      {data?.contractorExport?._id && (
        <ContractorExportView contractorExport={data?.contractorExport} />
      )}
    </Modal>
  );
}

import { FC } from "react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import _ from "lodash";
import lodash from "lodash";

interface InstallationServiceOrderViewProps {
  replacementServiceOrder: {
    request: any;
    farmingOutContractorUser: any;
    installationMeterSystemSyncStatus: string;
    replacementReason: string;
    rejectionReason: string;
    oldMeterNumber: string;
    oldMeterPhase: string;
    meter: any;
    replacementType: string;
    farmingOutContractor: any;
    contractorAssigner: any;
    installationType: {
      code: string;
      name: string;
    };
    installationMeter: {
      modelMeta: {
        brandName: string;
        modelName: string;
        phase: string;
      };
      code: string;
    };
    requestedMeterPhase: string;
    servicePoint: {
      propertyImageUrls: any;
      property: any;
      tariffClass: any;
      code: string;
    };
    notes: string;
    category: string | undefined;
    code: string;
    status: string;
    type: string;
    priority: string;
    region: {
      code: string;
      name: string;
    };
    district: {
      code: string;
      name: string;
    };
    contactPerson: {
      fullName: string;
      phoneNumber: string;
    };
    location: {
      address: string;
      community: string;
    };
    assigner: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    estimatedResolutionDate: string;
    createdAt: string;
    updatedAt: string;
  };
}

function Owner({ owner }: { owner: any }) {
  return (
    <div
      className={classNames(
        "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={
            owner?.name ||
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() ||
            "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {owner?.name ||
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() ||
            "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.username ||
            owner?.contactPerson?.phoneNumber ||
            owner?.phoneNumber ||
            "N/A"}
        </p>
      </div>
    </div>
  );
}

const InstallationServiceOrderView: FC<InstallationServiceOrderViewProps> = ({
  replacementServiceOrder,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <>
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
                Request Information
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about initial reading and meter details from prepayment
                system
              </p>
            </div>
            <div />
          </div>
          <div className="mt-4 space-y-3">
            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Date
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.createdAt
                      ? moment(replacementServiceOrder.createdAt).format(
                          dateFormat
                        )
                      : "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Estimated Resolution Date
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.estimatedResolutionDate
                      ? moment(
                          replacementServiceOrder.estimatedResolutionDate
                        ).format(dateFormat)
                      : "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Priority
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {_.startCase(replacementServiceOrder?.priority) || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Status
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {_.startCase(replacementServiceOrder?.status) || "N/A"}
                  </div>
                </div>
                {[
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Completed",
                ].includes(replacementServiceOrder.status) && (
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Prepayment Sync Status
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {replacementServiceOrder?.installationMeterSystemSyncStatus ||
                        "N/A"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Replacement Type
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {lodash.startCase(replacementServiceOrder?.replacementType) ||
                    "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Replacement Reason
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {replacementServiceOrder?.replacementReason || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Tariff Class
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {replacementServiceOrder?.servicePoint?.tariffClass?.code ||
                    "N/A"}{" "}
                  (
                  {replacementServiceOrder?.servicePoint?.tariffClass?.name ||
                    "N/A"}
                  )
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Old Meter Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {replacementServiceOrder?.meter?.code || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Old Meter Make & Model
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                {replacementServiceOrder?.meter?.modelMeta?.brandName || "N/A"}-{replacementServiceOrder?.meter?.modelMeta?.modelName || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Old Meter Phase
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                {replacementServiceOrder?.meter?.phase || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Requested Meter Phase
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {replacementServiceOrder?.requestedMeterPhase|| "N/A"}
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className="pt-6">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
                Customer Information
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about initial reading and meter details from prepayment
                system
              </p>
            </div>
            <div />
          </div>
          <div className="mt-4 space-y-3">
            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Service Point Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.servicePoint?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Full Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.contactPerson?.fullName || "N/A"}
                  </div>
                </div>

                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Phone Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.contactPerson?.phoneNumber ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Region
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.region?.name || "N/A"}
                  </div>
                </div>
                <div className="row-span-4 col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Premise Images
                  </span>
                  <div className="mt-2 grid grid-cols-1 gap-3">
                    {replacementServiceOrder?.servicePoint?.propertyImageUrls?.map(
                      (image: string) =>
                        wrapImage(
                          <img
                            src={image}
                            alt={"back"}
                            className="w-full h-48 text-xs object-cover object-center rounded"
                          />
                        )
                    )}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    District
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.district?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Community
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.location?.community || "N/A"}
                  </div>
                </div>

                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Address
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {replacementServiceOrder?.location?.address || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {replacementServiceOrder?.status !== "AssignedToSupplier" && (
          <div className="pt-6">
            <div className="flex justify-between items-center cursor-pointer">
              <div>
                <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
                  Assignment Information
                </h3>
                <p className="mt-1 text-xs text-gray-500">
                  Details about initial reading and meter details from
                  prepayment system
                </p>
              </div>
              <div />
            </div>
            <div className="mt-4 space-y-3">
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {replacementServiceOrder?.installationMeter?.code ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Make & Model
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {replacementServiceOrder?.installationMeter?.modelMeta
                        ?.brandName || "N/A"}{" "}
                      -
                      {replacementServiceOrder?.installationMeter?.modelMeta
                        ?.modelName || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Phase
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {replacementServiceOrder?.installationMeter?.modelMeta
                        ?.phase || "N/A"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Assigned By
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    <Owner
                      owner={replacementServiceOrder?.contractorAssigner}
                    />
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Farming Out Contractor
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    <Owner
                      owner={replacementServiceOrder?.farmingOutContractor}
                    />
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Farming Out Agent
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    <Owner
                      owner={replacementServiceOrder?.farmingOutContractorUser}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InstallationServiceOrderView;

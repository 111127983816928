import { gql, useLazyQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { TextInput } from "components/core";
import { MeterBrandPicker, MeterModelPicker } from "containers";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { wrapClick } from "utils";
import { IMeterShipmentFormSchema, ShipmentInfoFormSchema } from "./schema";

const GET_CODE = gql`
  query GetMeterShipmentCode {
    code: getMeterShipmentCode
  }
`;

interface MetersInfoFormProps {
  handleNext: (values: IMeterShipmentFormSchema["shipmentInfo"]) => void;
  handlePrevious: () => void;
  initialValues: IMeterShipmentFormSchema["shipmentInfo"];
  values: IMeterShipmentFormSchema;
  handleCancel: () => void;
}

const MetersInfoForm: FC<MetersInfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
}) => {
  const [getCode] = useLazyQuery(GET_CODE, {
    fetchPolicy: "network-only"
  });
  const form = useFormik<IMeterShipmentFormSchema["shipmentInfo"]>({
    initialValues,
    validationSchema: ShipmentInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  useEffect(() => {
    if (!form.values.code) {
      getCode().then(({ data }) => {
        if (data?.code) {
          form.setFieldValue("code", data?.code);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.code]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="rounded-md bg-primary-100 mx-6 mt-3 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-primary-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-primary-700">

              Download a sample shipment file (Excel)
            </p>
            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <a href='https://subs-production.s3.us-west-2.amazonaws.com/templates/meter-shipment-template.xlsx'
                target='_blank'
                download
                rel='noreferrer' className="whitespace-nowrap font-medium text-primary-700 hover:text-primary-600">
                Click Here to download
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Shipment Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="">
              <TextInput
                id="code"
                label="Shipment Code"
                type="text"
                placeholder="e.g. Wasion"
                {...form}
                disabled
              />
            </div>

            <div className="col-span-2">
              <TextInput
                id="description"
                label="Shipment Description"
                type="text"
                placeholder="e.g. Adenta June, 2023 3 Phase Shipment"
                {...form}
              />
            </div>
          </div>
        </div>

        <div className="pt-6">
          <span className="text-xs font-light">Meter Model Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="">
              <MeterBrandPicker
                id="brand"
                label="Meter Brand"
                placeholder="e.g. Huawei"
                required={true}
                {...form}
              />
            </div>

            {form.values.brand && (
              <div className="">
                <MeterModelPicker
                  id="model"
                  label="Meter Model"
                  placeholder="eg. Wasion"
                  required={true}
                  filter={{ brand: form.values.brand?._id }}
                  {...form}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default MetersInfoForm;
